// Override Boostrap variables
// Import Bootstrap source files from node_modules
@import '_bootstrap-variables';
@import '~bootswatch/dist/zephyr/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/zephyr/bootswatch';
body {
  margin: 0;
  min-height: 100%
}

a {
  color: $black;
  

  &:hover {
    color: $gray-700;
  }
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.single-page {
  width: 100vh;
  height: calc(100vh - #{$header-height} - #{$footer-height});
  max-height: calc(100vh - #{$header-height} - #{$footer-height});
}

.app-container {
  box-sizing: border-box;
  .view-container {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    .card {
      padding: 1rem;
    }
    
  }
}

.view-routes {
  max-width:1800px;
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0;
  width: 99% !important;
  height: calc(100vh - 110px) !important;
  margin: 5px;
  z-index: 1000;
  padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem;
  line-height: 1rem;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Temporary workaround for availity-reactstrap-validation */
.invalid-feedback {
  display: inline;
}

/* other generic styles */

.title {
  font-size: 1.25em;
  margin: 1px 10px 1px 10px;
}

.description {
  font-size: 0.9em;
  margin: 1px 10px 1px 10px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
}

.error {
  color: white;
  background-color: red;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: keep-all;
}

.preserve-space {
  white-space: pre-wrap;
}

.small-font-size {
  font-size: 0.8em;
}

.margin-zero {
  margin:0 !important;
}

.hidden-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

fieldset legend {
  font-size: inherit;
  margin-bottom: 0;
}



/* padding helpers */

@mixin pad($size, $side) {
  @if $size== '' {
    @if $side== '' {
      .pad {
        padding: 10px !important;
      }
    } @else {
      .pad {
        padding-#{$side}: 10px !important;
      }
    }
  } @else {
    @if $side== '' {
      .pad-#{$size} {
        padding: #{$size}px !important;
      }
    } @else {
      .pad-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px !important;
      }
    }
  }
}

@include pad('', '');
@include pad('2', '');
@include pad('3', '');
@include pad('5', '');
@include pad('10', '');
@include pad('20', '');
@include pad('25', '');
@include pad('30', '');
@include pad('50', '');
@include pad('75', '');
@include pad('100', '');
@include pad('4', 'top');
@include pad('5', 'top');
@include pad('10', 'top');
@include pad('20', 'top');
@include pad('25', 'top');
@include pad('30', 'top');
@include pad('50', 'top');
@include pad('75', 'top');
@include pad('100', 'top');
@include pad('4', 'bottom');
@include pad('5', 'bottom');
@include pad('10', 'bottom');
@include pad('20', 'bottom');
@include pad('25', 'bottom');
@include pad('30', 'bottom');
@include pad('50', 'bottom');
@include pad('75', 'bottom');
@include pad('100', 'bottom');
@include pad('5', 'right');
@include pad('10', 'right');
@include pad('20', 'right');
@include pad('25', 'right');
@include pad('30', 'right');
@include pad('50', 'right');
@include pad('75', 'right');
@include pad('100', 'right');
@include pad('5', 'left');
@include pad('10', 'left');
@include pad('20', 'left');
@include pad('25', 'left');
@include pad('30', 'left');
@include pad('50', 'left');
@include pad('75', 'left');
@include pad('100', 'left');

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* end of padding helpers */

.no-margin {
  margin: 0;
}
@mixin voffset($size) {
  @if $size== '' {
    .voffset {
      margin-top: 2px !important;
    }
  } @else {
    .voffset-#{$size} {
      margin-top: #{$size}px !important;
    }
  }
}

@include voffset('');
@include voffset('5');
@include voffset('10');
@include voffset('15');
@include voffset('30');
@include voffset('40');
@include voffset('60');
@include voffset('80');
@include voffset('100');
@include voffset('150');

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

button.anchor-btn {
  background: none;
  border: none;
  padding: 0;
  align-items: initial;
  text-align: initial;
  width: 100%;
}

a.anchor-btn:hover {
  text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

textarea {
  height: 140px;
}

/* ==========================================================================
Bigger Form Checkbox Styles
Check <ValidatedInput  className={key + '-'+ value.type} /> in form-utils.tsx
========================================================================== */

.done-boolean{
  margin-left: 8px;
  margin-top: 7px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/* ==========================================================================
Action Widget styles
========================================================================== */
.column{
   display: inline-block;
   overflow-x: hidden;
   width: 100%;
 }

/* Column size when close */
.side-column {
  display: inline-block;
  vertical-align: top;
  max-width: 6em;
  float: left;
  right:0;
  background-color: #eee;
  overflow-x: hidden;
 }

.side-column-open {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  max-width: 22em;
  float: right;
  right:0;
  background-color: #eee;
  overflow-x: hidden;
}

.side-menu {
  padding: 5em 0 0;
  right:0;
  background-color:  #eee;
  height:100%;
  max-width: 22em;
  overflow-x: hidden;
}

.side-icon{
  font-size:1.5em;
  color: $info;
}

.side-icon-open{
  font-size:1.3em;
  color: $info;
}

.widget-link{
  text-decoration: none;
}

.side-icon-open, .side-icon, .widget-link:hover{
  cursor: pointer;
  color: $info;
}

.side-menu-content {
  height:calc(99vh - #{$header-height} - #{$footer-height});
  margin:0;
}

.widget-form {
  text-align: center;
  width:100%;
}

.widget-input{
  width:100%;
  height: 5em;
  background-color: #f8f8f8;
  border-radius: 0.2em;
  resize: vertical;
}

/* Form detail tabs */

.tabs{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-top:0;
  padding-top:0;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 9;
}

.activeTab {
  background-color: $gray-200;
  padding: 0.5rem;
  margin-top:0;
  cursor: pointer;
  margin-bottom: 0;
}

.form-detail-modal-body {
  padding-top: 0;
  z-index: 2;
  background-color: $gray-200;
  min-height: 30em;
}

.form-detail-body{
  padding: 2rem;
  max-height: 70vh;
  overflow-y: auto;
}


.inactiveTab {
  color:white;
  background-color: $blue;
  padding: 0.5rem;
  margin-top: 0;
  cursor: pointer;
}

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}


.breadcrumb {
  border: none;
}
/* breadcrumb delimiter */
.breadcrumb-item + .breadcrumb-item::before {
  content: "/" !important;
}


/* --- Accordion styling */

.accordion-button {
  background-color: $white;
  color: $dark;

  &:hover {
    background-color: $secondary-light;
  }

  &:not(.collapsed) {
    color: $dark;
  }
}

.accordion-body {
  padding-top: 0;
}

/* --- end Accordion styling */


.scroll-container {
  padding: 0;
  margin: 0;
  position: relative;

  .scroll-overlay {
    position: absolute;
    background-color: rgba(0,0,0,0);
    width: 100%;
    top: 75%;
    bottom: 0;
    left: 0;
    height: 25%;
    pointer-events: none;
    transition: opacity 0.25s;
    opacity: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgb(65,185,38);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 40%, rgba(255,255,255,0) 100%);
    }
  }
}

.loading-bar {
  height: 3px;
  background-color: $primary;
  position: absolute;
  top: 0px;
  z-index: 1031;
}

/* --- form engine rjsf generated validation messages --- */
.error-detail {
  margin-top: -.5rem;
  list-style: none;
  padding-left: 0;
  
  & li {
    @extend .invalid-feedback;
  }
}

/* --- Make unavailable dates in the date picker calendar more distinct --- */
.rdp-calendar tbody td.text-muted {
  opacity: 0.6;
}

.user-listing-card {
  border-radius: 1em;
  border: none;
  box-shadow: 0px 14px 30px rgb(34 35 58 / 12%);
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

@media print {
  .avoid-page-break {
    page-break-inside: avoid;
  }
}
